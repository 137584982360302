import { useContext } from 'react';
import { PermissionsContext } from '@/lib/layout';
import { ReadOnlyField } from '@/lib/pages/forms/components';

export const AutoFilled = ({
  value,
  label,
  type = undefined,
  wideLabel,
  stretch = false,
}: {
  value: string | number | undefined | null;
  label: string;
  wideLabel?: boolean;
  stretch?: boolean;
  type?: 'currency' | 'percentage';
}) => {
  const { editMode } = useContext(PermissionsContext);

  if (!editMode) {
    return (
      <ReadOnlyField
        label={label}
        value={value}
        mask={type}
        stretch={stretch}
        wideLabel={wideLabel}
      />
    );
  }

  return (
    <div className="flex items-center gap-4">
      <p
        className={`${stretch ? `${wideLabel ? 'w-2/3' : 'w-1/3'}` : `${wideLabel ? 'w-2/5' : 'w-1/5'}`} text-sm font-semibold leading-5`}
      >
        {label}
      </p>
      <div
        className={`${stretch ? `${wideLabel ? 'w-1/3' : 'w-2/3'}` : `${wideLabel ? 'w-1/5' : 'w-2/5'}`} h-10 rounded bg-brand-200 px-3 py-2 text-brand-500`}
      >
        {value || 'Auto Filled'}
      </div>
    </div>
  );
};

export default AutoFilled;
