import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { allAuthProvider } from '@/lib/auth/auth';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';

const LoginButton = () => {
  return (
    <div className="mt-auto border-t border-t-[#E4E4E7] p-4 text-[white]">
      <Link to={'/auth/login'}>Login</Link>
    </div>
  );
};

const AuthenticatedProfile = ({ email }: { email: string }) => {
  const navigate = useNavigate();

  return (
    <div className="mt-auto border-t border-t-[#E4E4E7] p-4 text-[white]">
      <div className="flex">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className="flex w-full items-center justify-start p-0 hover:bg-transparent hover:text-[white]"
            >
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[red]">
                {email.slice(0, 2).toUpperCase()}
              </div>
              <span className="ml-4">{email.split('@')[0]}</span>
              <ChevronDownIcon className="ml-auto h-4 w-4" />
              <span className="sr-only">Open user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {/* <DropdownMenuItem
              onClick={() => {
                navigate('/profile');
              }}
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.99981 1.44043C5.86799 1.44043 4.13981 3.16861 4.13981 5.30043C4.13981 7.07209 5.33338 8.56497 6.96028 9.01884C5.6736 9.17011 4.57089 9.6209 3.74217 10.4376C2.69647 11.4682 2.1665 13.0029 2.1665 15.007C2.1665 15.2832 2.39036 15.507 2.6665 15.507C2.94265 15.507 3.1665 15.2832 3.1665 15.007C3.1665 13.1712 3.64984 11.9326 4.4441 11.1499C5.23983 10.3657 6.42725 9.96042 7.99976 9.96042C9.57227 9.96042 10.7597 10.3657 11.5555 11.1499C12.3498 11.9326 12.8331 13.1712 12.8331 15.007C12.8331 15.2832 13.057 15.507 13.3331 15.507C13.6092 15.507 13.8331 15.2832 13.8331 15.007C13.8331 13.0029 13.3032 11.4682 12.2574 10.4376C11.4287 9.62091 10.326 9.17013 9.03932 9.01885C10.6662 8.56499 11.8598 7.0721 11.8598 5.30043C11.8598 3.16861 10.1316 1.44043 7.99981 1.44043ZM5.13981 5.30043C5.13981 3.72089 6.42028 2.44043 7.99981 2.44043C9.57935 2.44043 10.8598 3.72089 10.8598 5.30043C10.8598 6.87996 9.57935 8.16043 7.99981 8.16043C6.42028 8.16043 5.13981 6.87996 5.13981 5.30043Z"
                  fill="#09090B"
                />
              </svg>

              <span className="ml-2">Profile</span>
            </DropdownMenuItem> */}
            {/* <DropdownMenuItem onClick={() => {}}>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.942383 8.50007C0.942383 4.60222 4.10222 1.44238 8.00007 1.44238C11.8979 1.44238 15.0578 4.60222 15.0578 8.50007C15.0578 12.3979 11.8979 15.5578 8.00007 15.5578C4.10222 15.5578 0.942383 12.3979 0.942383 8.50007ZM8.00007 2.44238C4.65451 2.44238 1.94238 5.15451 1.94238 8.50007C1.94238 11.8456 4.65451 14.5578 8.00007 14.5578C11.3456 14.5578 14.0578 11.8456 14.0578 8.50007C14.0578 5.15451 11.3456 2.44238 8.00007 2.44238ZM8.80009 11.7004C8.80009 12.1422 8.44192 12.5004 8.00009 12.5004C7.55826 12.5004 7.20009 12.1422 7.20009 11.7004C7.20009 11.2585 7.55826 10.9004 8.00009 10.9004C8.44192 10.9004 8.80009 11.2585 8.80009 11.7004ZM6.36687 7.16703C6.36687 6.39319 7.0327 5.66703 8.00021 5.66703C8.96771 5.66703 9.63354 6.39319 9.63354 7.16703C9.63354 7.73923 9.30929 8.0294 8.80727 8.33437C8.74858 8.37003 8.68235 8.4085 8.6124 8.44912L8.61239 8.44913C8.41818 8.56192 8.19538 8.69132 8.02623 8.82312C7.77637 9.0178 7.50021 9.32042 7.50021 9.78037C7.50021 10.0565 7.72406 10.2804 8.00021 10.2804C8.27635 10.2804 8.50021 10.0565 8.50021 9.78036L8.50016 9.77924C8.49968 9.76952 8.49738 9.72373 8.64085 9.61194C8.75375 9.52398 8.8817 9.4498 9.04627 9.35438L9.04629 9.35437C9.12942 9.30617 9.22188 9.25256 9.32647 9.18902C9.89112 8.846 10.6335 8.29617 10.6335 7.16703C10.6335 5.80754 9.48603 4.66703 8.00021 4.66703C6.51438 4.66703 5.36687 5.80754 5.36687 7.16703C5.36687 7.44317 5.59073 7.66703 5.86687 7.66703C6.14302 7.66703 6.36687 7.44317 6.36687 7.16703Z"
                  fill="#09090B"
                />
              </svg>

              <span className="ml-2">Help Center</span>
            </DropdownMenuItem> */}
            <DropdownMenuItem
              onClick={() => {
                allAuthProvider.logout();
                navigate('/auth/login');
              }}
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.79987 1.59961C4.52373 1.59961 4.29987 1.82347 4.29987 2.09961C4.29987 2.37575 4.52373 2.59961 4.79987 2.59961H12.7999C12.8183 2.59961 12.8332 2.61453 12.8332 2.63294V14.3663C12.8332 14.3847 12.8183 14.3996 12.7999 14.3996H4.79987C4.52373 14.3996 4.29987 14.6235 4.29987 14.8996C4.29987 15.1758 4.52373 15.3996 4.79987 15.3996H12.7999C13.3706 15.3996 13.8332 14.937 13.8332 14.3663V2.63294C13.8332 2.06225 13.3706 1.59961 12.7999 1.59961H4.79987ZM7.02009 5.74606C6.82483 5.55079 6.50824 5.55079 6.31298 5.74606C6.11772 5.94132 6.11772 6.2579 6.31298 6.45316L7.85943 7.99961H0.533203C0.257061 7.99961 0.0332031 8.22347 0.0332031 8.49961C0.0332031 8.77575 0.257061 8.99961 0.533203 8.99961H7.85943L6.31298 10.5461C6.11772 10.7413 6.11772 11.0579 6.31298 11.2532C6.50824 11.4484 6.82483 11.4484 7.02009 11.2532L9.42009 8.85316C9.61535 8.6579 9.61535 8.34132 9.42009 8.14606L7.02009 5.74606Z"
                  fill="#09090B"
                />
              </svg>

              <span className="ml-2">Log out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export const Profile = () => {
  // const { isAuthenticated, user, requiresReauthentication } = useAuthInfo();
  let data = useLoaderData();

  if (data == undefined) {
    return null;
  }

  let { username, isAuthenticated } = data as {
    username: string | null;
    isAuthenticated: boolean;
  };

  if (!isAuthenticated) {
    return <LoginButton />;
  }
  if (username) {
    return <AuthenticatedProfile email={username} />;
  }

  // TODO: Maybe a loader here?
  return null;
};
