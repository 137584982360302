import {
  formatAsCurrency,
  formatAsPercentage,
  formatAsNumber,
} from '@/lib/pages/forms/helpers';

export const ReadOnlyField = ({
  value,
  label,
  height,
  orientation = 'horizontal',
  mask,
  stretch,
  wideLabel,
  inputClass,
}: {
  value: string | number | undefined | null;
  label?: string;
  maxHeight?: string;
  height?: string;
  orientation?: 'horizontal' | 'vertical';
  mask?: 'currency' | 'percentage';
  stretch?: boolean;
  wideLabel?: boolean;
  inputClass?: string;
}) => {
  const getMaskedValue = (value: string | number | undefined | null) => {
    if (mask === 'currency') {
      return value !== null && value !== '' && value
        ? formatAsCurrency(Number(value))
        : 'N/A';
    }

    if (mask === 'percentage') {
      return value !== null && value !== '' && value
        ? formatAsPercentage(Number(value))
        : 'N/A';
    }

    if (mask === 'number') {
      return value !== null && value !== '' && value
        ? formatAsNumber(Number(value))
        : 'N/A';
    }

    return (!mask && value) || 'N/A';
  };

  return (
    <div
      className={`flex ${orientation === 'horizontal' ? 'items-center gap-4' : 'w-full flex-col'}`}
    >
      {label ? (
        <p
          className={`${stretch ? `${wideLabel ? 'w-2/3' : 'w-1/3'}` : `${wideLabel ? 'w-2/5' : 'w-1/5'}`} text-sm font-semibold leading-5`}
        >
          {label}
        </p>
      ) : null}
      <div
        className={`${orientation === 'horizontal' ? `${stretch ? `${wideLabel ? 'w-1/3' : 'w-2/3'}` : `${wideLabel ? 'w-1/5' : 'w-2/5'}`}` : ''} ${inputClass} h-10 rounded border border-[#D4D4D8] bg-brand-50 px-3 py-2 ${height ? `min-h-[${height}px]` : 'overflow-hidden overflow-ellipsis text-nowrap'}`}
        title={String(value || '')}
      >
        {getMaskedValue(value)}
      </div>
    </div>
  );
};

export default ReadOnlyField;
