import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  glacierAgenciesApiListWritingCompanyOptions,
  glacierUsersApiCurrentUserOptions,
  glacierUsersApiRelatedUsersOptions,
} from '@/lib/heyapi/@tanstack/react-query.gen';
import { useMemo } from 'react';
import { Selectable } from '@/lib/pages/forms/components/Select';
import {
  AgencyWithStatesSchema,
  CurrentUserSchema,
  GlacierAgenciesApiListWritingCompanyResponse,
  RelatedAgentsSchema,
} from '../heyapi';

export type CurrentUserData = {
  currentUser: UseQueryResult<CurrentUserSchema, Error>;
  agenciesSelectable: Array<Selectable<string>>;
  authorizedStates: Record<string, AgencyWithStatesSchema['authorizedStates']>;
};

export function useCurrentUserData(): CurrentUserData {
  const currentUser = useQuery(glacierUsersApiCurrentUserOptions());

  const [agencies, authorizedStates] = useMemo(() => {
    const data = currentUser.data?.agentData;
    const uwData = currentUser.data?.underwriterData;
    const selectableAgency = new Set<{ id: string; name: string }>();
    const authorized: Record<
      string,
      AgencyWithStatesSchema['authorizedStates']
    > = {};

    if (data) {
      data.forEach((agencyWithStates) => {
        selectableAgency.add({
          id: agencyWithStates.agencyId!,
          name: agencyWithStates.name,
        });

        authorized[agencyWithStates.agencyId!] =
          agencyWithStates.authorizedStates;
      });
    }

    if (uwData) {
      uwData.forEach((agency) => {
        selectableAgency.add({
          id: agency.agencyId!,
          name: agency.name,
        });

        authorized[agency.agencyId!] = agency.authorizedStates;
      });
    }
    return [[...selectableAgency], authorized];
  }, [currentUser.data]);

  const agenciesSelectable: Array<Selectable<string>> = useMemo(() => {
    return agencies.map(({ id, name }) => ({ label: name, value: id! }));
  }, [agencies]);

  return { currentUser, agenciesSelectable, authorizedStates };
}

export type RelatedUsersData = {
  relatedUsers: UseQueryResult<RelatedAgentsSchema, Error>;
  usersByAgencySelectable: Record<string, Array<Selectable<string>>>;
  agentsByAgencySelectable: Record<string, Array<Selectable<string>>>;
};

export function useRelatedUsersData(): RelatedUsersData {
  const relatedUsers = useQuery(glacierUsersApiRelatedUsersOptions());

  const usersByAgencySelectable: Record<string, Selectable<string>[]> =
    useMemo(() => {
      const { data } = relatedUsers;
      if (!data) return {};

      return Object.entries(data.agency).reduce(
        (acc, [agency, users]) => {
          acc[agency] = users.map((user) => {
            return {
              label: `${user.firstName} ${user.lastName}`,
              value: `${user.id!}`,
            };
          });
          return acc;
        },
        {} as Record<string, Selectable<string>[]>
      );
    }, [relatedUsers.data]);

  const agentsByAgencySelectable: Record<string, Selectable<string>[]> =
    useMemo(() => {
      const { data } = relatedUsers;
      if (!data) return {};

      return Object.entries(data.agency).reduce(
        (acc, [agency, users]) => {
          acc[agency] = users
            .filter((u) => u.role == 'agent')
            .map((user) => {
              return {
                label: `${user.firstName} ${user.lastName}`,
                value: `${user.id!}`,
              };
            });
          return acc;
        },
        {} as Record<string, Selectable<string>[]>
      );
    }, [relatedUsers.data]);

  return { relatedUsers, usersByAgencySelectable, agentsByAgencySelectable };
}

export type WritingCompaniesData = {
  writingCompanies: UseQueryResult<
    GlacierAgenciesApiListWritingCompanyResponse,
    Error
  >;
  writingCompaniesSelectable: Array<Selectable<string>>;
};

export function useWritingCompaniesData(): WritingCompaniesData {
  const writingCompanies = useQuery(
    glacierAgenciesApiListWritingCompanyOptions()
  );

  const writingCompaniesSelectable = useMemo(() => {
    const { data } = writingCompanies;
    if (!data) return [];

    return writingCompanies.data?.map(({ uuid, name, shortName }) => ({
      label: `${name} (${shortName?.toUpperCase()})`,
      value: uuid!,
    }));
  }, [writingCompanies]);

  return { writingCompanies, writingCompaniesSelectable };
}
