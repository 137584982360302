import { createContext, useState, Suspense, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import Sidebar from './Sidebar';
import { ThemeProvider } from '@/lib/components/theme-provider';
import { Toaster } from '@/components/ui/toaster';
import Loader from '../components/Loader';
import Footer from './Footer';
import Header from './Header';
import Meta from './Meta';
import { useCurrentUserData } from '@/lib/utils/hooks';
import { useQuery } from '@tanstack/react-query';
import { glacierPolicyApiGetPolicyPermissionsOptions } from '../heyapi/@tanstack/react-query.gen';

export type userRole = 'underwriter' | 'agent';

export const userRoleEnum = {
  UNDERWRITER: 'underwriter',
  AGENT: 'agent',
} as const;

export const PermissionsContext = createContext<{
  editMode: boolean;
  setEditMode: (arg0: boolean) => void;
  role: userRole;
}>({
  editMode: false,
  setEditMode: () => {},
  role: userRoleEnum.AGENT,
});

const Layout = () => {
  const { id } = useParams();
  const { data } = useQuery({
    enabled: id != undefined,
    ...glacierPolicyApiGetPolicyPermissionsOptions({
      path: {
        account_id: id!,
      },
    }),
  });
  const currentUserData = useCurrentUserData();
  const [editMode, setEditMode] = useState<boolean>(true);
  const [role, setRole] = useState<userRole>(
    (currentUserData.currentUser.data?.underwriterData?.length || 0) > 0
      ? userRoleEnum.UNDERWRITER
      : userRoleEnum.AGENT
  );

  useEffect(() => {
    const roleVal =
      (currentUserData.currentUser.data?.underwriterData?.length || 0) > 0
        ? userRoleEnum.UNDERWRITER
        : userRoleEnum.AGENT;
    if (roleVal !== role) {
      setRole(roleVal);
    }
  }, [currentUserData.currentUser.data?.underwriterData]);

  useEffect(() => {
    if (data && (data == 'READ' || data == 'UNAUTHORIZED')) {
      setEditMode(false);
    }
    if (data && data == 'WRITE') {
      setEditMode(true);
    }

    // TODO: Add block if unauthorized & reroute to main screen
  }, [data]);

  return (
    <ThemeProvider>
      <PermissionsContext.Provider value={{ editMode, setEditMode, role }}>
        <Meta />
        <div className="flex flex-col dark:bg-black dark:text-white">
          <Header />
          <div className="flex flex-col pl-[20vw]">
            <Sidebar />
            <main className="wrapper mx-auto w-full max-w-full pt-0">
              {' '}
              {/* w-[93%] */}
              {/* TODO: See if shads class "wrapper" is better */}
              {/* TODO: Add fallback component */}
              <Suspense fallback={<Loader />}>
                <Outlet />
              </Suspense>
            </main>
            {/* <Button
              onClick={() => setEditMode((s) => !s)}
              className="fixed bottom-8 right-32 ml-auto mt-auto bg-brand-yellow text-brand-0 transition hover:bg-brand-yellow hover:opacity-70 hover:duration-300"
            >
              {editMode ? 'Read mode' : 'Edit mode'}
            </Button> */}
            <Footer />
            <Toaster />
          </div>
        </div>
      </PermissionsContext.Provider>
    </ThemeProvider>
  );
};

export default Layout;
