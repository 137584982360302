import {
  type UseFormRegister,
  type Control,
  useFieldArray,
} from 'react-hook-form';

import { CoverageBasis } from '@/lib/heyapi/types.gen';
import { PlSubTypeEnum } from '@/lib/heyapi/types.gen';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { DateInput as DateField } from '@/lib/pages/forms/components/DateInput';
import { PLCoverageSectionForm } from '../../plCoverageSection/types';

/**
 * Simplifying this compared to above since this is very narrowly defined to one form
 */
export function AddAnotherComponentSociety({
  control,
  register,
  watchCoverageBasis,
  plSubType,
}: {
  control: Control<PLCoverageSectionForm>;
  register: UseFormRegister<PLCoverageSectionForm>;
  watchCoverageBasis: CoverageBasis | null | undefined;
  plSubType: PlSubTypeEnum | null | undefined;
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'componentSocieties',
  });

  const handleSaveDentist = () => {
    // dentistMutation.mutate(fields.fields[index]); // TODO: add mutation
    // update(index, { ...fields[index], needToSave: false });
  };

  return (
    <div>
      <div className="flex items-start">
        <p className="mb-4 w-3/4 font-semibold">
          Please Add Component Societies to Schedule
        </p>

        <div className="flex w-1/4 justify-end">
          <Button
            onClick={() => {
              append({
                name: null,
                priorActs: null,
              });
            }}
            type="button"
            variant="outline"
            className="h-auto px-3 py-1"
          >
            + Add Component Society
          </Button>
        </div>
      </div>
      <div className="mb-4 flex flex-col rounded border">
        <div
          className={`grid gap-4 border-b bg-brand-100 text-sm text-brand-400 ${watchCoverageBasis === CoverageBasis.OCCURRENCE && plSubType === PlSubTypeEnum.DENTAL_ASSOCIATION ? 'grid-cols-[1fr,100px]' : 'grid-cols-[1fr,1fr,100px]'}`}
        >
          <div className="flex flex-col space-y-2 px-4 py-3">Name</div>
          <div
            className={`flex flex-col space-y-2 px-4 py-3 ${watchCoverageBasis === CoverageBasis.OCCURRENCE && plSubType === PlSubTypeEnum.DENTAL_ASSOCIATION ? 'hidden' : ''}`}
          >
            Prior Acts Date
          </div>
        </div>
        {!fields.length ? (
          <div className="flex flex-col space-y-2 px-4 py-3 text-sm">
            Click &quot;Add Component Society&quot; to add new component society
          </div>
        ) : null}
        {fields.map((item, index) => {
          // console.log(watchDentists[index].designation);
          return (
            <div
              className={`grid w-full gap-4 ${watchCoverageBasis === CoverageBasis.OCCURRENCE && plSubType === PlSubTypeEnum.DENTAL_ASSOCIATION ? 'grid-cols-[1fr,100px]' : 'grid-cols-[1fr,1fr,100px]'}`}
              key={item.id}
            >
              <div className="flex flex-col space-y-2 px-4 py-2">
                <Input
                  className="border-none"
                  placeholder="Type here"
                  {...register(`componentSocieties.${index}.name`)}
                />
              </div>

              <div
                className={`${watchCoverageBasis === CoverageBasis.OCCURRENCE && plSubType === PlSubTypeEnum.DENTAL_ASSOCIATION ? 'hidden' : ''} flex flex-col space-y-2 px-4`}
              >
                <DateField
                  control={control}
                  name={register(`componentSocieties.${index}.priorActs`).name}
                  label=""
                  orientation="vertical"
                />
              </div>

              <div className="mb-4 flex items-center space-y-2 px-4 py-3">
                <Button
                  variant="ghost"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    remove(index);
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.86576 1.10156C5.58961 1.10156 5.36576 1.32542 5.36576 1.60156C5.36576 1.8777 5.58961 2.10156 5.86576 2.10156H10.1324C10.4086 2.10156 10.6324 1.8777 10.6324 1.60156C10.6324 1.32542 10.4086 1.10156 10.1324 1.10156H5.86576ZM5.26576 4.2349H10.7324V12.8682L5.26576 12.8682V4.2349ZM10.7324 3.20156C10.8213 3.20156 10.9074 3.21315 10.9894 3.2349H12.2658C12.5419 3.2349 12.7658 3.45875 12.7658 3.7349C12.7658 4.01104 12.5419 4.2349 12.2658 4.2349H11.7324V12.8682C11.7324 13.4205 11.2847 13.8682 10.7324 13.8682H5.26576C4.71347 13.8682 4.26576 13.4205 4.26576 12.8682V4.2349H3.73242C3.45628 4.2349 3.23242 4.01104 3.23242 3.7349C3.23242 3.45875 3.45628 3.2349 3.73242 3.2349H5.00876C5.09076 3.21315 5.17691 3.20156 5.26576 3.20156H10.7324Z"
                      fill="#09090B"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          );
        })}
        {/* {uuids.map((i, idx) => {
          return (
            <>
              <RowComponent<T>
                register={register}
                unregister={unregister}
                allowDelete={uuids.length > 1}
                designationOptions={designationOptions}
                uuid={i}
                deleteCallback={deleteCallback}
                watch={watch}
              />
              {idx !== uuids.length - 1 && <Separator />}
            </>
          );
        })} */}
      </div>
    </div>
  );
}

export default AddAnotherComponentSociety;
