import type {
  FieldValues,
  UseFormRegister,
  // UseFormUnregister,
  UseFormWatch,
  FieldArrayWithId,
  // Control,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import type { OptionalPolicyDetails } from '@/lib/heyapi';

export function AddAnotherOwner<T extends FieldValues>({
  watch,
  register,
  fields,
  append,
  remove,
  update,
}: {
  onAddDentist: React.MouseEventHandler<HTMLButtonElement>;
  register: UseFormRegister<T & FieldValues>;
  // unregister: UseFormUnregister<T & FieldValues>;
  watch: UseFormWatch<keyof T & FieldValues>;
  // deleteCallback: (uuid: string) => void;
  // uuids: string[];
  fields: FieldArrayWithId<T>;
  // control: Control<T & OptionalPolicyDetails>;
  append: UseFieldArrayAppend<T & OptionalPolicyDetails>;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<T & OptionalPolicyDetails>;
}) {
  const watchOwner = watch('owners');

  const handleSaveDentist = (index: number) => {
    // dentistMutation.mutate(fields.fields[index]); // TODO: add mutation

    update(index, { ...watchOwner[index], needToSave: false });
  };

  // TODO: Checkboxes are custom, make sure they are in line with rest of shadcn;

  return (
    <div>
      <div className="flex items-start">
        <p className="mb-4 w-3/4 font-semibold">
          Please Designate The Dentist(s) Authorized To Make Policy Level
          Coverage Decisions On This Policy:
        </p>
        <div className="flex w-1/4 justify-end">
          <Button
            onClick={() => {
              append({
                firstName: '',
                percentage: '',
                needToSave: true,
              });
            }}
            type="button"
            variant="outline"
            className="h-auto px-3 py-1"
          >
            + Add Owner
          </Button>
        </div>
      </div>
      <div className="mb-4 flex flex-col rounded border">
        <div className="grid grid-cols-[1fr,1fr,120px] gap-4 border-b bg-brand-100 text-sm text-brand-400">
          <div className="flex flex-col space-y-2 px-4 py-3">First Name</div>
          <div className="flex flex-col space-y-2 px-4 py-3">Percentage</div>
          <div className="flex flex-col space-y-2 px-4 py-3">Actions</div>
        </div>
        {fields.map((item, index) => {
          // console.log(watchOwner[index].designation);
          return (
            <div
              className="grid w-full grid-cols-[1fr,1fr,120px] gap-4"
              key={item.id}
            >
              <div className="flex flex-col space-y-2 px-4 py-3">
                <Input
                  className="border-none"
                  placeholder="Type here"
                  {...register(`owners.${index}.firstName`)}
                />
              </div>

              <div className="flex flex-col space-y-2 px-4 py-3">
                <Input
                  className="border-none"
                  placeholder="Type here"
                  type="number"
                  {...register(`owners.${index}.percentage`)}
                />
              </div>

              <div className="mb-4 flex items-center space-y-2 px-4 py-3">
                <Button
                  variant="ghost"
                  type="button"
                  className={`${watchOwner.length > 1 ? '' : 'pointer-events-none hidden'}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    remove(index);
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.86576 1.10156C5.58961 1.10156 5.36576 1.32542 5.36576 1.60156C5.36576 1.8777 5.58961 2.10156 5.86576 2.10156H10.1324C10.4086 2.10156 10.6324 1.8777 10.6324 1.60156C10.6324 1.32542 10.4086 1.10156 10.1324 1.10156H5.86576ZM5.26576 4.2349H10.7324V12.8682L5.26576 12.8682V4.2349ZM10.7324 3.20156C10.8213 3.20156 10.9074 3.21315 10.9894 3.2349H12.2658C12.5419 3.2349 12.7658 3.45875 12.7658 3.7349C12.7658 4.01104 12.5419 4.2349 12.2658 4.2349H11.7324V12.8682C11.7324 13.4205 11.2847 13.8682 10.7324 13.8682H5.26576C4.71347 13.8682 4.26576 13.4205 4.26576 12.8682V4.2349H3.73242C3.45628 4.2349 3.23242 4.01104 3.23242 3.7349C3.23242 3.45875 3.45628 3.2349 3.73242 3.2349H5.00876C5.09076 3.21315 5.17691 3.20156 5.26576 3.20156H10.7324Z"
                      fill="#09090B"
                    />
                  </svg>
                </Button>
                <Button
                  variant="ghost"
                  type="button"
                  className={`${watchOwner[index]?.needToSave ? '' : 'pointer-events-none hidden'} !mt-0`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSaveDentist(index);
                  }}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 2C3.22386 2 3 2.22386 3 2.5V12.5C3 12.7761 3.22386 13 3.5 13H11.5C11.7761 13 12 12.7761 12 12.5V4.70711L9.29289 2H3.5ZM2 2.5C2 1.67157 2.67157 1 3.5 1H9.5C9.63261 1 9.75979 1.05268 9.85355 1.14645L12.7803 4.07322C12.921 4.21388 13 4.40464 13 4.60355V12.5C13 13.3284 12.3284 14 11.5 14H3.5C2.67157 14 2 13.3284 2 12.5V2.5ZM4.75 7.5C4.75 7.22386 4.97386 7 5.25 7H7V5.25C7 4.97386 7.22386 4.75 7.5 4.75C7.77614 4.75 8 4.97386 8 5.25V7H9.75C10.0261 7 10.25 7.22386 10.25 7.5C10.25 7.77614 10.0261 8 9.75 8H8V9.75C8 10.0261 7.77614 10.25 7.5 10.25C7.22386 10.25 7 10.0261 7 9.75V8H5.25C4.97386 8 4.75 7.77614 4.75 7.5Z"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AddAnotherOwner;
