import { useContext } from 'react';
import type { Control, FieldValues, Path } from 'react-hook-form';

import {
  // Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
// import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
// import { toast } from "@/components/ui/use-toast"

import { ReadOnlyField } from '@/lib/pages/forms/components';

import { PermissionsContext } from '@/lib/layout';

function CustomTextarea<T extends FieldValues>({
  control,
  // options,
  name,
  label,
  placeholder,
  orientation = 'horizontal',
  type = 'text',
  maxHeight = '500px',
  height,
  resize,
  required = false,
  disabled = false,
  wideLabel = false,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<T>;
  // options: string[];
  name: Path<T>;
  label: string;
  placeholder?: string;
  orientation?: 'horizontal' | 'vertical';
  type?: string;
  maxHeight?: string;
  height?: string;
  resize?: boolean;
  required?: boolean;
  disabled?: boolean;
  wideLabel?: boolean;
}) {
  const { editMode } = useContext(PermissionsContext);
  // console.log(control);
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        if (!editMode) {
          return (
            <ReadOnlyField
              label={label}
              value={field.value}
              orientation={orientation}
              maxHeight={maxHeight}
              height={height || '80'}
            />
          );
        } else {
          return (
            <FormItem
              className={`flex ${orientation === 'horizontal' ? 'gap-4' : 'w-full flex-col'}`}
            >
              <FormLabel
                className={`${orientation === 'horizontal' ? `${wideLabel ? 'w-2/5' : 'w-1/5'}` : 'mb-2'} font-semibold leading-5`}
              >
                {label}
                {required && <span className="text-red-400">*</span>}
              </FormLabel>
              <div
                className={`${orientation === 'horizontal' ? `${wideLabel ? 'w-1/5' : 'w-2/5'}` : ''} !mt-0`}
              >
                <FormControl>
                  <Textarea
                    // maxHeight={maxHeight}
                    style={{
                      maxHeight,
                      resize: resize ? '' : 'none',
                      height: height || '',
                    }}
                    // defaultValue={field.value}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </div>
              {/* <FormDescription>
                This is your public display name.
              </FormDescription> */}
            </FormItem>
          );
        }
      }}
    />

    // <FormField
    //   control={control}
    //   name={name}
    //   render={({ field }) => {
    //     // console.log(field.ref);
    //     return (
    //       <FormItem className="space-y-2">
    //         <FormLabel className="leading-3">{label}</FormLabel>
    //         <FormControl>
    //           <RadioGroup
    //             onValueChange={field.onChange}
    //             // defaultValue={field.value}
    //             value={field.value}
    //             className="flex space-y-1"
    //           >
    //             <div className="flex items-center gap-2">
    //               {options.map((option) => (
    //                 <FormItem
    //                   className="flex items-center space-x-3 space-y-0"
    //                   key={`${label}_${option}`}
    //                 >
    //                   <FormControl>
    //                     <RadioGroupItem value={option} />
    //                   </FormControl>
    //                   <FormLabel className="font-normal capitalize">
    //                     {option}
    //                   </FormLabel>
    //                 </FormItem>
    //               ))}
    //             </div>
    //           </RadioGroup>
    //         </FormControl>
    //         <FormMessage />
    //       </FormItem>
    //     );
    //   }}
    // />
  );
}

export { CustomTextarea as Textarea };
