import { useState, useEffect, useMemo } from 'react';
import { LoaderCircle } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { Check, ChevronsUpDown, Copy } from 'lucide-react';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import {
  glacierPolicyApiGetPolicyQueryKey,
  glacierPolicyApiGetFormulasQueryKey,
  glacierPolicyApiGetPolicySummaryQueryKey,
  glacierPolicyApiGetDentistQueryKey,
  glacierPolicyApiGetSpecialFormsMutation,
  glacierPolicyApiAssignableOptions,
  glacierPolicyApiAssignMutation,
  glacierPolicyApiGetPolicyPermissionsQueryKey,
} from '@/lib/heyapi/@tanstack/react-query.gen';

import type { Policy, Transaction, UserSchema2 } from '../heyapi';
import {
  QuoteTypeToLabel,
  PolicyTypeToLabel,
  TransactionStatusToLabel,
} from '@/lib/pages/forms/fields';
import { formatDate, toDateString } from '../utils/dates';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  glacierPolicyApiGetPolicyOptions,
  glacierPolicyApiRatePolicyMutation,
} from '../heyapi/@tanstack/react-query.gen';

import { PolicyDetails } from '@/lib/heyapi/types.gen';

import { toast } from '@/components/ui/use-toast';
import { formatCurrency } from '../utils/currency';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { RelatedUsersData, useRelatedUsersData } from '../utils/hooks';
import { useQueue } from '@uidotdev/usehooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Select } from '@/lib/pages/forms/components';
import { Form } from '@/components/ui/form';

const Header = () => {
  const { id } = useParams();

  const { data } = useQuery({
    ...glacierPolicyApiGetPolicyOptions({
      path: {
        account_id: id!,
      },
    }),
  });

  return <HeaderView policy={data} />;
};

type BackendRateError = {
  path: string[];
  attribute: string;
  code: string;
  message: string;
};
type BackendRateErrors = {
  errors: BackendRateError[];
};

function isBackendRatingError(error: unknown): error is BackendRateErrors {
  return (
    typeof error === 'object' &&
    error !== null &&
    'errors' in error &&
    Array.isArray((error as BackendRateErrors).errors) &&
    (error as BackendRateErrors).errors.length > 0
  );
}

function isOtherRatingError(error: unknown): error is BackendRateError {
  return typeof error === 'object' && error !== null && 'path' in error;
}

function formattedPathAndMessage(error: BackendRateError): [string, string] {
  const entityPath = error.path.slice(0, -1).join(' -> '); // Exclude the last part (attribute)
  let message = error.message;

  if (error.attribute != '') {
    message = `'${error.attribute}' is required`;
  }

  return [entityPath, message];
}

function formatError(error: BackendRateError): string {
  const [entityPath, message] = formattedPathAndMessage(error);
  return `${entityPath} ${message}`;
}
function formatErrors(errors: BackendRateErrors): string {
  const errorMap: Record<string, string[]> = {};

  errors.errors.forEach((error) => {
    const [entityPath, message] = formattedPathAndMessage(error);

    if (!errorMap[entityPath]) {
      errorMap[entityPath] = [];
    }
    errorMap[entityPath].push(message);
  });

  let formattedErrors = '';

  Object.keys(errorMap).forEach((entityPath) => {
    formattedErrors += `\n${entityPath}:\n`;
    errorMap[entityPath].forEach((message) => {
      formattedErrors += `    - ${message}\n`;
    });
  });

  return formattedErrors;
}

const DebugTransaction = ({ transaction }: { transaction: Transaction }) => {
  const [copiedRequest, setCopiedRequest] = useState(false);
  const [copiedResponse, setCopiedResponse] = useState(false);

  const copyToClipboard = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  if (transaction.latestAttempt) {
    const { request, response } = transaction.latestAttempt;

    return (
      <div className="max-w-screen grid h-[80vh] w-full grid-cols-2 gap-4">
        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Request to Rater</CardTitle>
            <Button
              variant="outline"
              size="icon"
              onClick={() =>
                copyToClipboard(
                  JSON.stringify(request, null, 2),
                  setCopiedRequest
                )
              }
              aria-label="Copy request JSON"
            >
              {copiedRequest ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </Button>
          </CardHeader>
          <CardContent>
            <code>
              {JSON.stringify(transaction.latestAttempt?.request, null, 2)}
            </code>
          </CardContent>
        </Card>

        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Response from Rater</CardTitle>
            <Button
              variant="outline"
              size="icon"
              onClick={() =>
                copyToClipboard(
                  JSON.stringify(response, null, 2),
                  setCopiedResponse
                )
              }
              aria-label="Copy request JSON"
            >
              {copiedResponse ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </Button>
          </CardHeader>
          <CardContent>
            <code>
              {JSON.stringify(transaction.latestAttempt?.response, null, 2)}
            </code>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <p>
      No rating has been recorded yet. When you see "Policy Rated" or "Error
      While Rating Policy", come back here
    </p>
  );
};

const Transfer = ({ accountId }: { accountId: string }) => {
  const { data } = useQuery({
    ...glacierPolicyApiAssignableOptions({
      path: {
        account_id: accountId,
      },
    }),
  });
  return <TransferView accountId={accountId} related={data || []} />;
};

type TransferQuote = {
  id: string;
};

const TransferView = ({
  related,
  accountId,
}: {
  accountId: string;
  related: Array<UserSchema2>;
}) => {
  const qc = useQueryClient();
  const permissionsQk = glacierPolicyApiGetPolicyPermissionsQueryKey({
    path: { account_id: accountId },
  });
  const summariesQk = glacierPolicyApiGetPolicySummaryQueryKey();
  const policyQk = glacierPolicyApiGetPolicyQueryKey({
    path: {
      account_id: accountId,
    },
  });

  const transfer = useMutation({
    ...glacierPolicyApiAssignMutation(),
    onSuccess(data, variables, context) {
      qc.refetchQueries({ queryKey: permissionsQk });
      qc.refetchQueries({ queryKey: summariesQk });
      qc.refetchQueries({ queryKey: policyQk });
      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">Transferred</code>
          </pre>
        ),
      });
    },
  });

  const form = useForm<TransferQuote>({
    defaultValues: {
      id: undefined,
    },
  });

  const { control, handleSubmit } = form;

  const selectable = related.map((u) => ({
    label: `${u.firstName} ${u.lastName} <${u.email}>`,
    value: String(u.id!),
  }));

  const onSubmit: SubmitHandler<TransferQuote> = (data) => {
    transfer.mutate({
      path: {
        account_id: accountId,
      },
      body: {
        userId: Number(data.id),
      },
    });
  };
  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="overflow-scroll">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Transfer</CardTitle>
          </CardHeader>
          <CardContent className="pt-4">
            <Select
              control={control}
              name="id"
              options={selectable}
              label=""
              placeholder="Choose an Agent or Underwriter"
            />
          </CardContent>
          <CardFooter className="pt-4">
            <Button
              type="submit"
              className="fixed bottom-8 right-10 ml-auto mt-auto bg-brand-green text-brand-0 transition hover:bg-brand-green hover:opacity-70 hover:duration-300"
            >
              Transfer
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

const TransactionMessage = ({ tx }: { tx: Transaction }) => {
  return null;

  // BRING BACK LATER
  return (
    <div className="w-full bg-brand-yellow px-10 py-1 leading-[1.5rem] text-[white]">
      <strong>Status Message: </strong>
      <span>{tx?.statusMessage || 'None'}</span>
    </div>
  );
};

type Message = {
  content: string;
  level: 'INFO' | 'ERROR';
};

const StandardMessageHeader = ({
  message,
}: {
  message: Message | undefined;
}) => {
  if (!message) {
    return null;
  }

  // BRING BACK LATER
  return (
    <div
      className={`w-full ${message.level == 'INFO' ? 'bg-amber-500' : 'bg-red-500'} px-10 py-1 leading-[1.5rem] text-[white]`}
    >
      <strong>Status Message: </strong>
      <span>{message.content}</span>
    </div>
  );
};

const HeaderView = ({ policy }: { policy: Policy | undefined }) => {
  const queryClient = useQueryClient();

  const { id, dentistId } = useParams();
  const policy_qk = glacierPolicyApiGetPolicyQueryKey({
    path: {
      account_id: id!,
    },
  });

  const formulas_qk = glacierPolicyApiGetFormulasQueryKey({
    path: {
      account_id: id!,
    },
  });

  const summaryKey = glacierPolicyApiGetPolicySummaryQueryKey();

  const dentistQK = glacierPolicyApiGetDentistQueryKey({
    path: {
      account_id: id!,
      dentist_id: dentistId || '',
    },
  });

  const [loading, setLoading] = useState(false);
  const [issuing, setIssuing] = useState(false);
  const [forming, setForming] = useState(false);
  const [message, setMessage] = useState<Message | undefined>(undefined);
  const [dialogContent, setDialogContent] = useState<
    'RATING' | 'TRANSFER' | undefined
  >(undefined);

  const [expanded, setExpanded] = useState(
    localStorage.getItem('expanded') === 'true'
  );

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    localStorage.setItem('expanded', expanded.toString());
  }, [expanded]);

  const effectiveDate = useMemo(() => {
    return policy?.details?.policyEffectiveDate
      ? formatDate(policy.details?.policyEffectiveDate)
      : '--';
  }, [policy?.details?.policyEffectiveDate]);

  const transactionEffectiveDate = useMemo(() => {
    return policy?.latestTransaction?.effectiveDate
      ? toDateString(policy.latestTransaction.effectiveDate)
      : null;
  }, [policy?.latestTransaction?.effectiveDate]);

  const expirationDate = useMemo(() => {
    return policy?.details?.policyExpirationDate
      ? formatDate(policy.details?.policyExpirationDate)
      : '--';
  }, [policy?.details?.policyExpirationDate]);

  const firstNamedInsured = useMemo(() => {
    const { firstName, middleName, lastName, professionalDesignation } =
      policy?.details || ({} as PolicyDetails);

    let individualName = [firstName, middleName, lastName]
      .filter((v) => v)
      .join(' ');

    if (professionalDesignation) {
      individualName = `${individualName}, ${professionalDesignation.join(' ')}`;
    }

    return policy?.details?.entityName || individualName || '--';
  }, [
    policy?.details?.entityName,
    policy?.details?.firstName,
    policy?.details?.middleName,
    policy?.details?.lastName,
  ]);

  const assignedUser = useMemo(() => {
    return policy?.assignedTo?.firstName
      ? `${policy.assignedTo.firstName} ${policy.assignedTo.lastName}`
      : '--';
  }, [policy?.assignedTo?.firstName, policy?.assignedTo?.lastName]);

  const statusStage = useMemo(() => {
    const quoteType = policy?.details?.quoteType
      ? QuoteTypeToLabel[policy?.details?.quoteType]
      : policy?.details?.policyType
        ? PolicyTypeToLabel[policy?.details?.policyType]
        : '--';

    const latestTransaction = policy?.latestTransaction?.status
      ? TransactionStatusToLabel[policy.latestTransaction.status]
      : '--';

    return `${quoteType} / ${latestTransaction}`;
  }, [
    policy?.details?.quoteType,
    policy?.details?.policyType,
    policy?.latestTransaction?.status,
  ]);

  const issuePolicy = useMutation({
    ...glacierPolicyApiGetSpecialFormsMutation(),
    onSuccess(data, variables) {
      setIssuing(false);

      setMessage({
        content: 'Policy Specimen generated. Check Document Warehouse',
        level: 'INFO',
      });
    },
    onError(error) {
      setIssuing(false);
      setForming(false);
      setMessage({
        content: 'Error generating the Policy Specimen',
        level: 'ERROR',
      });
    },
  });

  const quoteForm = useMutation({
    ...glacierPolicyApiGetSpecialFormsMutation(),
    onSuccess(data, variables) {
      setForming(false);

      setMessage({
        content:
          'Quote Proposal generated. Check Document Warehouse. Generating Specimen',
        level: 'INFO',
      });
      issuePolicy.mutate({
        path: { account_id: policy!.pk! },
        query: { special_form: 'policy_specimen' },
      });

      setIssuing(true);
    },
    onError(error) {
      setForming(false);
      setIssuing(true);
      setMessage({
        content: 'Error generating the Quote Proposal',
        level: 'ERROR',
      });
    },
  });

  const rate = useMutation({
    ...glacierPolicyApiRatePolicyMutation(),
    onSuccess(data, variables) {
      queryClient.setQueryData(
        glacierPolicyApiGetPolicyOptions({ ...variables }).queryKey,
        data
      );
      queryClient.refetchQueries({ queryKey: policy_qk });
      queryClient.refetchQueries({ queryKey: formulas_qk });
      queryClient.refetchQueries({ queryKey: summaryKey });
      queryClient.refetchQueries({ queryKey: dentistQK });
      setLoading(false);
      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">Policy Rated</code>
          </pre>
        ),
      });
    },
    onError(error) {
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: policy_qk });
      queryClient.invalidateQueries({ queryKey: formulas_qk });
      let msg = 'Missing required fields for rating';
      if (isOtherRatingError(error)) {
        msg = `${msg}\n\n${formatError(error)}`;
      }
      if (isBackendRatingError(error)) {
        msg = `${msg}\n\n${formatErrors(error)}`;
      }
      msg += `\nRating DID NOT Occur\n`;
      alert(msg);
    },
  });

  return (
    <header className="bg-base-100/80 pointer-events-none sticky top-0 z-20 !text-[14px]">
      {/* backdrop-blur-md */}
      <section className="ml-[calc(20vw-1px)] flex flex-col items-center justify-between sm:text-xs 2xl:text-[14px]">
        <div className="pointer-events-auto mx-auto w-full overflow-hidden border-brand-200 bg-brand-100 pl-10">
          <div className="flex w-full justify-between gap-8">
            <div className="container flex w-10/12 justify-between">
              <div className="flex w-4/12 flex-col gap-1 py-4 pr-8">
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">First Name Insured:</span>
                  <span
                    className="block w-7/12 overflow-hidden overflow-ellipsis whitespace-nowrap font-bold"
                    title={firstNamedInsured}
                  >
                    {firstNamedInsured}
                  </span>
                </div>
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">Agency:</span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={policy?.details?.agencyName || 'agencyName'}
                  >
                    {policy?.details?.agencyName || '--'}
                  </span>
                </div>
                {expanded && (
                  <>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Assigned User:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title={assignedUser}
                      >
                        {assignedUser}
                      </span>
                    </div>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">
                        Change Effective Date:
                      </span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title={transactionEffectiveDate || ''}
                      >
                        {transactionEffectiveDate}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <Separator className="my-4 h-auto" orientation="vertical" />

              <div className="flex w-4/12 flex-col gap-1 px-8 py-4">
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">
                    <span>Quote Number:</span>
                  </span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={policy?.policyNumber || ''}
                  >
                    {policy?.policyNumber}
                  </span>
                </div>
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">Primary Practice State:</span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={policy?.details?.policyContractState || ''}
                  >
                    {policy?.details?.policyContractState || '--'}
                  </span>
                </div>
                {expanded && (
                  <>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Status/Stage:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title={statusStage}
                      >
                        {statusStage}
                      </span>
                    </div>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Policy Period</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title={`${effectiveDate} - ${expirationDate}`}
                      >
                        {effectiveDate} - {expirationDate}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <Separator className="my-4 h-auto" orientation="vertical" />

              <div className="flex w-4/12 flex-col gap-1 px-8 py-4">
                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">
                    <span>Policy Number:</span>
                  </span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title={policy?.boundPolicyNumber || ''}
                  >
                    {policy?.boundPolicyNumber}
                  </span>
                </div>

                <div className="flex gap-4 text-sm">
                  <span className="block w-5/12">Billed Amount:</span>
                  <span
                    className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                    title="Billed Amount"
                  >
                    {formatCurrency(
                      (policy?.latestTransaction?.billedPremium || 0) +
                        (policy?.latestTransaction?.billedTax || 0)
                    )}
                  </span>
                </div>
                {expanded && (
                  <>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Written Amount:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title="Written Premium"
                      >
                        {formatCurrency(
                          (policy?.latestTransaction?.writtenPremium || 0) +
                            (policy?.latestTransaction?.writtenTax || 0)
                        )}
                      </span>
                    </div>
                    <div className="flex gap-4 text-sm">
                      <span className="block w-5/12">Annual Amount:</span>
                      <span
                        className="block w-7/12 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
                        title="Annual Amount"
                      >
                        {formatCurrency(
                          (policy?.latestTransaction?.annualPremium || 0) +
                            (policy?.latestTransaction?.annualTax || 0)
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex w-2/12 justify-end">
              <Separator className="my-4 h-auto" orientation="vertical" />
              <div className="m-4 flex h-auto w-8/12 max-w-[100px] items-center justify-center">
                {loading && (
                  <div className="flex flex-row-reverse items-center gap-4">
                    <span className="text-sm">Rating</span>
                    <LoaderCircle className="h-10 w-10 animate-spin text-brand-200" />
                  </div>
                )}
                {!loading && (
                  <Dialog>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          type="button"
                          className="h-10 w-10 bg-[none] p-0 hover:bg-brand-200"
                        >
                          <DotsHorizontalIcon className="!hover:bg-[none] h-4 w-4 rounded-none text-[black]" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => {}} disabled={true}>
                          <span className="ml-2">Approve</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => {}} disabled={true}>
                          <span className="ml-2">Decline</span>
                        </DropdownMenuItem>

                        <DialogTrigger
                          asChild
                          onClick={() => setDialogContent('TRANSFER')}
                        >
                          <DropdownMenuItem>
                            <span className="ml-2">Transfer</span>
                          </DropdownMenuItem>
                        </DialogTrigger>

                        <DropdownMenuItem
                          onClick={() => {
                            setForming(true);
                            setMessage({
                              content:
                                'Generating Specimen. Proposal & Bundle will be in Document Warehouse when complete',
                              level: 'INFO',
                            });
                            quoteForm.mutate({
                              path: { account_id: policy!.pk! },
                              query: { special_form: 'quote_proposal' },
                            });
                          }}
                          disabled={issuing || forming}
                        >
                          <span className="ml-2">
                            {issuing || forming
                              ? 'Generating Quote...'
                              : 'Generate Quote'}
                          </span>
                        </DropdownMenuItem>

                        <DropdownMenuItem
                          onClick={() => {
                            setLoading(true);
                            rate.mutate({ path: { account_id: policy!.pk! } });
                          }}
                        >
                          <span className="ml-2">Rate</span>
                        </DropdownMenuItem>
                        <DialogTrigger
                          asChild
                          onClick={() => setDialogContent('RATING')}
                        >
                          <DropdownMenuItem>
                            <span className="ml-2">Debug Previous Rating</span>
                          </DropdownMenuItem>
                        </DialogTrigger>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <DialogContent className="w-full overflow-auto">
                      {dialogContent == 'RATING' && (
                        <DebugTransaction
                          transaction={policy?.latestTransaction!}
                        />
                      )}
                      {dialogContent == 'TRANSFER' && (
                        <Transfer accountId={id!} />
                      )}
                    </DialogContent>
                  </Dialog>
                )}
              </div>
              <Button
                type="button"
                className="h-auto w-4/12 rounded-none bg-brand-200 px-4 text-black hover:bg-brand-300"
                onClick={handleExpanded}
              >
                <ChevronsUpDown />
              </Button>
            </div>
          </div>
        </div>
        <StandardMessageHeader message={message} />
      </section>
    </header>
  );
};

export default Header;
