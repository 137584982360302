import { ConditionalRenewalNoticeRequestSchema, YesNoEnum } from '@/lib/heyapi';

export type RenewalNotice = {
  conditions?: string | null;
  displayPremium?: string;
};

export type RenewalNoticeUpdatedDict = {
  conditions?: string | null;
  displayPremium?: string;
};

export const DefaultValues: Required<RenewalNotice> = {
  conditions: '',
  displayPremium: YesNoEnum.NO,
};

export function frontToBack(
  form: RenewalNotice
): ConditionalRenewalNoticeRequestSchema {
  return {
    display_premium: form.displayPremium === YesNoEnum.YES,
    conditions: form.conditions ?? '',
  };
}

export function backToFront(data: RenewalNotice): RenewalNotice {
  return {
    ...DefaultValues,
    ...data,
  };
}
