import type { LoaderFunctionArgs } from 'react-router-dom';
import { redirect } from 'react-router-dom';
import { glacierPolicyApiGetPolicyWithSummariesOptions } from '../heyapi/@tanstack/react-query.gen';
import type { QueryClient } from '@tanstack/react-query';
import { CF_HEADERS } from '../fetch_utils';
import type { PolicyWithSummaries } from '../heyapi';

/**
 * react-query + router loader example taken from:
 *
 * https://tanstack.com/query/latest/docs/framework/react/examples/react-router
 * https://tkdodo.eu/blog/react-query-meets-react-router
 *
 * Pages should fetch using react-query based on the ID via useSuspenseQuery
 */
export const loader =
  (qc: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const { id } = params;

    if (!id) {
      throw new Error('No ID provided');
    }

    const data = await qc.ensureQueryData(
      glacierPolicyApiGetPolicyWithSummariesOptions({
        path: { account_id: id },
        fetch: (_request) => {
          return fetch(_request, {
            credentials: 'include',
            headers: CF_HEADERS,
          });
        },
      })
    );
    const urlSegments = new URL(request.url).pathname.split('/');
    const lastSegment = urlSegments[urlSegments.length - 1];

    const summaryKey = {
      'prof-liability-dentist': 'dentistsSummary',
      'practice-locations': 'locations',
      'owned-entities': 'ownedEntitiesSummary',
    }[lastSegment] as keyof PolicyWithSummaries | undefined;

    const summaries = summaryKey ? data[summaryKey] : [];
    const summary = Array.isArray(summaries) ? summaries.at(0) : undefined;

    if (summary) {
      return redirect(`${request.url}/${summary.pk}`);
    }

    return { policyId: id };
  };
