import {
  type UseFormRegister,
  type Control,
  useFieldArray,
} from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import type { PolicyDetailsForm } from '../../policyDetails/types';

/**
 * Simplifying this compared to above since this is very narrowly defined to one form
 */
export function FirstNamedInsured({
  control,
  register,
}: {
  control: Control<PolicyDetailsForm>;
  register: UseFormRegister<PolicyDetailsForm>;
}) {
  const { fields } = useFieldArray({
    control,
    name: 'professionalDesignations',
  });

  return (
    <div className="mb-4 flex flex-col rounded border">
      <div className="grid grid-cols-[1fr,1fr,1fr,1fr] gap-4 border-b bg-brand-100 text-sm text-brand-400">
        <div className="flex flex-col space-y-2 px-4 py-3">First Name</div>
        <div className="flex flex-col space-y-2 px-4 py-3">Middle Name</div>
        <div className="flex flex-col space-y-2 px-4 py-3">Last Name</div>
        <div className="flex flex-col space-y-2 px-4 py-3">Designation</div>
      </div>
      <div className="flex flex-col space-y-2 px-4 py-3">
        <Input
          className="border-none"
          placeholder="Type here"
          {...register(`firstName`)}
        />
      </div>

      <div className="flex flex-col space-y-2 px-4 py-3">
        <Input
          className="border-none"
          placeholder="Type here"
          {...register(`middleName`)}
        />
      </div>

      <div className="flex flex-col space-y-2 px-4 py-3">
        <Input
          className="border-none"
          placeholder="Type here"
          {...register(`lastName`)}
        />
      </div>
      <div className="flex flex-col space-y-2 px-4 py-3">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="justify-start text-brand-700">
              {fields.length ? (
                <>
                  <span className="rounded-full bg-brand-100 px-2 py-1">
                    {fields[0].designation.toUpperCase()}
                  </span>
                  {fields.length > 1 ? (
                    <>
                      &nbsp; ... &nbsp;
                      <span className="rounded-full bg-brand-100 px-2 py-1">
                        + {fields.length - 1}
                      </span>
                    </>
                  ) : null}
                </>
              ) : (
                <span className="text-brand-500">Designation</span>
              )}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-auto block"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.23484 10.0565C5.42371 9.85501 5.74013 9.84481 5.94158 10.0337L11.9996 15.7131L18.0576 10.0337C18.2591 9.84481 18.5755 9.85501 18.7644 10.0565C18.9532 10.2579 18.943 10.5743 18.7416 10.7632L12.3416 16.7632C12.1493 16.9435 11.85 16.9435 11.6576 16.7632L5.25764 10.7632C5.05618 10.5743 5.04598 10.2579 5.23484 10.0565Z"
                  fill="#09090B"
                />
              </svg>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-44">
            <div className="flex w-full flex-col items-start">
              {fields.map((field, idx: number) => {
                return (
                  <div
                    className="flex flex-row-reverse items-center px-2 py-3"
                    key={field.id}
                  >
                    <FormLabel
                      htmlFor={`dentists.${0}.professionalDesignation.${idx}`}
                      className="mx-2"
                      style={{ fontWeight: 'inherit' }}
                    >
                      {field.designation}
                    </FormLabel>
                    <div className="grid items-center justify-center">
                      <input
                        className="peer col-start-1 row-start-1 h-4 w-4 appearance-none rounded border border-slate-300 ring-transparent checked:border-brand-green checked:bg-brand-green dark:border-slate-600 dark:checked:border-primary forced-colors:appearance-auto"
                        type="checkbox"
                        value={field.designation}
                        id={field.id}
                        {...register(`professionalDesignations.${idx}.enabled`)}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-check pointer-events-none invisible col-start-1 row-start-1 h-4 w-4 stroke-white peer-checked:visible dark:text-violet-300 forced-colors:hidden"
                      >
                        <path d="M20 6 9 17l-5-5"></path>
                      </svg>
                    </div>
                  </div>
                );
              })}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

export default FirstNamedInsured;
