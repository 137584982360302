import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { SelectableYesNo } from '@/lib/pages/forms/fields';
import { Select } from '../Select';
import { Textarea } from '../Textarea';
import { useForm } from 'react-hook-form';
import { Form } from '@/components/ui/form';
import { glacierPolicyApiConditionalRenewalNoticeMutation } from '@/lib/heyapi/@tanstack/react-query.gen';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@/components/ui/use-toast';
import { useParams } from 'react-router-dom';
import type { RenewalNotice } from '@/lib/pages/forms/components/ConditionalRenewalNotice/types';
import { DefaultValues, backToFront, frontToBack } from './types';

export function ConditionalRenewalNotice() {
  const renewalData: RenewalNotice = {};
  const form = useForm<RenewalNotice>({
    defaultValues: DefaultValues,
    values: backToFront(renewalData),
  });

  const { control, handleSubmit } = form;
  const { id } = useParams();

  const renewalNotice = useMutation({
    ...glacierPolicyApiConditionalRenewalNoticeMutation(),
    onError: (error) => {
      toast({
        title: 'Error',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-red-300 p-4">
            <code className="text-white">{error as string}</code>
          </pre>
        ),
      });
    },
    onSuccess(data, _variables, _context) {
      const url = URL.createObjectURL(data);
      window.open(url, '_blank');

      toast({
        title: 'Success',
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">Printed!</code>
          </pre>
        ),
      });
    },
  });

  const onSubmit = (data: RenewalNotice) => {
    renewalNotice.mutate({
      path: {
        account_id: id!,
      },
      body: {
        ...frontToBack(data),
      },
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="container mb-2 bg-sky-50">
          <CardHeader className="flex w-full flex-row items-center justify-between">
            <CardTitle className="text-lg">
              Conditional Renewal Notice
            </CardTitle>
            <Button variant="outline" size="sm" className="bg-white">
              Print
            </Button>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col">
                <Textarea
                  control={control}
                  label="Conditions"
                  placeholder="Type here"
                  name="conditions"
                  orientation="horizontal"
                />
              </div>
              <div className="flex flex-col">
                <Select
                  options={SelectableYesNo}
                  label="Display Premium"
                  control={control}
                  name="displayPremium"
                  placeholder="Yes or No"
                  orientation="horizontal"
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </form>
    </Form>
  );
}

export default ConditionalRenewalNotice;
