import Cookies from 'js-cookie';
import { defaultHeaders, defaultHeadersInit, headers } from '../fetch_utils';

// Adapted from the allauth documents
// https://github.com/pennersr/django-allauth/blob/main/examples/react-spa/frontend/src/lib/allauth.js
export async function getCSRFToken() {
  const cookie = Cookies.get('csrftoken');
  return cookie;
  // DEPRECATING THIS PATH FOR NOW
  if (cookie && cookie != '') {
    return cookie;
  } else {
    let pageSource = await fetch(
      'https://glacier.aspen.dev.counterpartcloud.com/accounts/login/',
      {
        headers: defaultHeadersInit(),
        credentials: 'include',
      }
    ).then((r) => r.text());

    return /name="csrfmiddlewaretoken" value="(.*)"/.exec(pageSource)[1];
  }
}

const Client = Object.freeze({
  APP: 'app',
  BROWSER: 'browser',
});

const CLIENT = Client.BROWSER;

const BASE_URL = `${import.meta.env.VITE_AUTH_ENDPOINT}/${CLIENT}/v1`;

export const AuthProcess = Object.freeze({
  LOGIN: 'login',
  CONNECT: 'connect',
});

export const Flows = Object.freeze({
  VERIFY_EMAIL: 'verify_email',
  LOGIN: 'login',
  LOGIN_BY_CODE: 'login_by_code',
  SIGNUP: 'signup',
  PROVIDER_REDIRECT: 'provider_redirect',
  PROVIDER_SIGNUP: 'provider_signup',
  MFA_AUTHENTICATE: 'mfa_authenticate',
  REAUTHENTICATE: 'reauthenticate',
  MFA_REAUTHENTICATE: 'mfa_reauthenticate',
});

export const URLs = Object.freeze({
  // Meta
  CONFIG: BASE_URL + '/config',

  // Account management
  CHANGE_PASSWORD: BASE_URL + '/account/password/change',
  EMAIL: BASE_URL + '/account/email',
  PROVIDERS: BASE_URL + '/account/providers',

  // Account management: 2FA
  AUTHENTICATORS: BASE_URL + '/account/authenticators',
  RECOVERY_CODES: BASE_URL + '/account/authenticators/recovery-codes',
  TOTP_AUTHENTICATOR: BASE_URL + '/account/authenticators/totp',

  // Auth: Basics
  LOGIN: BASE_URL + '/auth/login',
  REQUEST_LOGIN_CODE: BASE_URL + '/auth/code/request',
  CONFIRM_LOGIN_CODE: BASE_URL + '/auth/code/confirm',
  SESSION: BASE_URL + '/auth/session',
  REAUTHENTICATE: BASE_URL + '/auth/reauthenticate',
  REQUEST_PASSWORD_RESET: BASE_URL + '/auth/password/request',
  RESET_PASSWORD: BASE_URL + '/auth/password/reset',
  SIGNUP: BASE_URL + '/auth/signup',
  VERIFY_EMAIL: BASE_URL + '/auth/email/verify',

  // Auth: 2FA
  MFA_AUTHENTICATE: BASE_URL + '/auth/2fa/authenticate',
  MFA_REAUTHENTICATE: BASE_URL + '/auth/2fa/reauthenticate',

  // Auth: Social
  PROVIDER_SIGNUP: BASE_URL + '/auth/provider/signup',
  REDIRECT_TO_PROVIDER: BASE_URL + '/auth/provider/redirect',
  PROVIDER_TOKEN: BASE_URL + '/auth/provider/token',

  // Auth: Sessions
  SESSIONS: BASE_URL + '/auth/sessions',

  // Auth: WebAuthn
  REAUTHENTICATE_WEBAUTHN: BASE_URL + '/auth/webauthn/reauthenticate',
  AUTHENTICATE_WEBAUTHN: BASE_URL + '/auth/webauthn/authenticate',
  LOGIN_WEBAUTHN: BASE_URL + '/auth/webauthn/login',
  WEBAUTHN_AUTHENTICATOR: BASE_URL + '/account/authenticators/webauthn',
});

export const AuthenticatorType = Object.freeze({
  TOTP: 'totp',
  RECOVERY_CODES: 'recovery_codes',
  WEBAUTHN: 'webauthn',
});

function postForm(action: string, data: any) {
  const f = document.createElement('form');
  f.method = 'POST';
  f.action = action;

  for (const key in data) {
    const d = document.createElement('input');
    d.type = 'hidden';
    d.name = key;
    d.value = data[key];
    f.appendChild(d);
  }
  document.body.appendChild(f);
  f.submit();
}

const tokenStorage = window.sessionStorage;

async function request(
  method: string,
  path: string,
  data?: any,
  optHeaders?: HeadersInit
): Promise<any> {
  const headers = defaultHeaders();
  if (optHeaders) {
    Object.entries(optHeaders).forEach(([key, value]) =>
      headers.append(key, JSON.stringify(value))
    );
  }

  const options: RequestInit = {
    method,
    credentials: 'include',
    headers,
  };

  // Don't pass along authentication related headers to the config endpoint.
  if (path.toString() !== URLs.CONFIG) {
    if (CLIENT === Client.BROWSER) {
      const csrfToken = await getCSRFToken();

      if (csrfToken) {
        headers.append('X-CSRFToken', csrfToken);
      }
    } else if (CLIENT === Client.APP) {
      // IMPORTANT!: Do NOT use `Client.APP` in a browser context, as you will
      // be vulnerable to CSRF attacks. This logic is only here for
      // development/demonstration/testing purposes...
      headers.append('User-Agent', 'django-allauth example app');
      const sessionToken = tokenStorage.getItem('sessionToken');
      if (sessionToken) {
        headers.append('X-Session-Token', sessionToken);
      }
    }
  }

  if (typeof data !== 'undefined') {
    options.body = JSON.stringify(data);
    headers.append('Content-Type', 'application/json');
  }
  const resp = await fetch(path, options);
  const msg = await resp.json();
  if (msg.status === 410) {
    tokenStorage.removeItem('sessionToken');
  }
  if (msg.meta?.session_token) {
    tokenStorage.setItem('sessionToken', msg.meta.session_token);
  }
  if (
    [401, 410].includes(msg.status) ||
    (msg.status === 200 && msg.meta?.is_authenticated)
  ) {
    const event = new CustomEvent('allauth.auth.change', { detail: msg });
    document.dispatchEvent(event);
  }

  return msg;
}

export async function login(data: any): Promise<any> {
  return request('POST', URLs.LOGIN, data);
}

export async function reauthenticate(data: any) {
  return await request('POST', URLs.REAUTHENTICATE, data);
}

export async function logout(): Promise<any> {
  return request('DELETE', URLs.SESSION);
}

export async function signUp(data: any) {
  return request('POST', URLs.SIGNUP, data);
}

export async function providerSignup(data: any) {
  return request('POST', URLs.PROVIDER_SIGNUP, data);
}

export async function getProviderAccounts() {
  return request('GET', URLs.PROVIDERS);
}

export async function disconnectProviderAccount(
  providerId: string,
  accountUid: string
) {
  return request('DELETE', URLs.PROVIDERS, {
    provider: providerId,
    account: accountUid,
  });
}

export async function requestPasswordReset(email: string) {
  return request('POST', URLs.REQUEST_PASSWORD_RESET, { email });
}

export async function requestLoginCode(email: string) {
  return request('POST', URLs.REQUEST_LOGIN_CODE, { email });
}

export async function confirmLoginCode(code: string) {
  return request('POST', URLs.CONFIRM_LOGIN_CODE, { code });
}

export async function getEmailVerification(key: string) {
  return request('GET', URLs.VERIFY_EMAIL, undefined, {
    'X-Email-Verification-Key': key,
  });
}

export async function getEmailAddresses() {
  return request('GET', URLs.EMAIL);
}
export async function getSessions() {
  return request('GET', URLs.SESSIONS);
}

export async function endSessions(ids: Array<string>) {
  return request('DELETE', URLs.SESSIONS, { sessions: ids });
}

export async function getAuthenticators() {
  return request('GET', URLs.AUTHENTICATORS);
}

export async function getTOTPAuthenticator() {
  return request('GET', URLs.TOTP_AUTHENTICATOR);
}

export async function mfaAuthenticate(code: string) {
  return request('POST', URLs.MFA_AUTHENTICATE, { code });
}

export async function mfaReauthenticate(code: string) {
  return request('POST', URLs.MFA_REAUTHENTICATE, { code });
}

export async function activateTOTPAuthenticator(code: string) {
  return request('POST', URLs.TOTP_AUTHENTICATOR, { code });
}

export async function deactivateTOTPAuthenticator() {
  return request('DELETE', URLs.TOTP_AUTHENTICATOR);
}

export async function getRecoveryCodes() {
  return request('GET', URLs.RECOVERY_CODES);
}

export async function generateRecoveryCodes() {
  return request('POST', URLs.RECOVERY_CODES);
}

export async function getConfig() {
  return request('GET', URLs.CONFIG);
}

export async function addEmail(email: string) {
  return request('POST', URLs.EMAIL, { email });
}

export async function deleteEmail(email: string) {
  return request('DELETE', URLs.EMAIL, { email });
}

export async function markEmailAsPrimary(email: string) {
  return request('PATCH', URLs.EMAIL, { email, primary: true });
}

export async function requestEmailVerification(email: string) {
  return request('PUT', URLs.EMAIL, { email });
}

export async function verifyEmail(key: string) {
  return request('POST', URLs.VERIFY_EMAIL, { key });
}

export async function getPasswordReset(key: string) {
  return request('GET', URLs.RESET_PASSWORD, undefined, {
    'X-Password-Reset-Key': key,
  });
}

export async function resetPassword(data: any) {
  return request('POST', URLs.RESET_PASSWORD, data);
}

type ChangePasswordParams = {
  current_password: string;
  new_password: string;
};

export async function changePassword(data: ChangePasswordParams) {
  return request('POST', URLs.CHANGE_PASSWORD, data);
}

export async function getAuth() {
  return request('GET', URLs.SESSION);
}

export async function authenticateByToken(
  providerId: string,
  token: string,
  process = AuthProcess.LOGIN
) {
  return request('POST', URLs.PROVIDER_TOKEN, {
    provider: providerId,
    token,
    process,
  });
}

export function redirectToProvider(
  providerId: string,
  callbackURL: string,
  process = AuthProcess.LOGIN
) {
  postForm(URLs.REDIRECT_TO_PROVIDER, {
    provider: providerId,
    process,
    callback_url: callbackURL,
    csrfmiddlewaretoken: getCSRFToken(),
  });
}

export async function getWebAuthnCreateOptions(passwordless: string) {
  let url = URLs.WEBAUTHN_AUTHENTICATOR;
  if (passwordless) {
    url += '?passwordless';
  }
  return request('GET', url);
}

export async function addWebAuthnCredential(name: string, credential: string) {
  return request('POST', URLs.WEBAUTHN_AUTHENTICATOR, {
    name,
    credential,
  });
}

export async function deleteWebAuthnCredential(ids: string) {
  return request('DELETE', URLs.WEBAUTHN_AUTHENTICATOR, {
    authenticators: ids,
  });
}

export async function updateWebAuthnCredential(id: string, data: object) {
  return request('PUT', URLs.WEBAUTHN_AUTHENTICATOR, { id, ...data });
}

export async function getWebAuthnRequestOptionsForReauthentication() {
  return request('GET', URLs.REAUTHENTICATE_WEBAUTHN);
}

export async function reauthenticateUsingWebAuthn(credential: string) {
  return request('POST', URLs.REAUTHENTICATE_WEBAUTHN, { credential });
}

export async function authenticateUsingWebAuthn(credential: string) {
  return request('POST', URLs.AUTHENTICATE_WEBAUTHN, { credential });
}

export async function loginUsingWebAuthn(credential: string) {
  return request('POST', URLs.LOGIN_WEBAUTHN, { credential });
}

export async function getWebAuthnRequestOptionsForLogin() {
  return request('GET', URLs.LOGIN_WEBAUTHN);
}

export async function getWebAuthnRequestOptionsForAuthentication() {
  return request('GET', URLs.AUTHENTICATE_WEBAUTHN);
}
