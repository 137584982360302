/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';

import { UsAddress, UsStateEnum } from '@/lib/heyapi/types.gen';

export function useDebouncedValue<Type>(
  val: Type,
  delay: number
): Type | undefined {
  const inputValue = JSON.stringify(val);
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  if (debouncedValue) {
    return JSON.parse(debouncedValue);
  }
  // return undefined;
}

// TODO: consider updating to named arguments
export const useDetermineVisibility = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  dependsOn: string | number = '',
  compareWith: string | string[],
  mode: 'exclude' | 'compare' = 'compare'
) => {
  const [show, setShow] = useState<boolean>(false);

  const dependsOnString = dependsOn.toString();

  useEffect(() => {
    if (mode === 'exclude') {
      if (compareWith.includes(dependsOnString)) {
        setShow(false);
      } else {
        setShow(true);
      }
    } else if (mode === 'compare') {
      if (dependsOnString === compareWith) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, [dependsOnString, show, setShow, compareWith, mode]);

  return [show];
};

// function getFields<T>(scheme: z.ZodRawShape): Record<keyof T, any> {
//   const getIssueObj = (key: string) => ({
//     path: [key],
//     message: 'Required',
//     expected: undefined,
//     received: undefined,
//     code: z.ZodIssueCode.invalid_literal,
//   });

//   const obj: Record<string, any> = {};

//   Object.keys(scheme).forEach((key) => {
//     const zodObject = scheme[key];

//     if (zodObject._def.typeName === 'ZodEnum') {
//       obj[key] = {
//         type: 'enum',
//         optional: false,
//         options: zodObject.options,
//         invalidate: (ctx: z.RefinementCtx) => {
//           ctx.addIssue(getIssueObj(key));
//         },
//       };
//     } else if (zodObject._def.typeName === 'ZodOptional') {
//       if (zodObject._def.innerType._def.typeName === 'ZodString') {
//         obj[key] = {
//           type: 'string',
//           optional: true,
//           invalidate: (ctx: z.RefinementCtx) => {
//             ctx.addIssue(getIssueObj(key));
//           },
//         };
//       } else if (zodObject._def.innerType._def.typeName === 'ZodEnum') {
//         obj[key] = {
//           type: 'enum',
//           optional: true,
//           options: zodObject._def.innerType.options,
//           invalidate: (ctx: z.RefinementCtx) => {
//             ctx.addIssue(getIssueObj(key));
//           },
//         };
//       } else if (zodObject._def.innerType._def.typeName === 'ZodArray') {
//         obj[key] = {
//           type: 'enum',
//           optional: true,
//           options: zodObject._def.innerType.element.options,
//           invalidate: (ctx: z.RefinementCtx) => {
//             ctx.addIssue(getIssueObj(key));
//           },
//         };
//       }
//       // console.log();
//     } else if (zodObject._def.typeName === 'ZodArray') {
//       // console.log(key);
//       obj[key] = {
//         type: 'enum',
//         optional: false,
//         options: zodObject.element.options,
//         invalidate: (ctx: z.RefinementCtx) => {
//           ctx.addIssue(getIssueObj(key));
//         },
//       };
//       // console.log(zodObject.element.options);
//     } else if (zodObject._def.typeName === 'ZodString') {
//       obj[key] = {
//         type: 'string',
//         optional: false,
//         invalidate: (ctx: z.RefinementCtx) => {
//           ctx.addIssue(getIssueObj(key));
//         },
//       };
//     }
//   });

//   return obj;
// }

// type DetailsFormScheme<TEnum> =
//   | {
//       type: 'enum';
//       optional: boolean;
//       options: TEnum[];
//       invalidate: (ctx: z.RefinementCtx) => void;
//     }
//   | {
//       type: 'string';
//       optional: boolean;
//       options: TEnum[];
//       invalidate: (ctx: z.RefinementCtx) => void;
//     };

// type PolicyDetailsShape = z.infer<
//   typeof FrontendApplicationSchema.shape.policyDetails
// >;

// type Result = {
//   [K in keyof PolicyDetailsShape]: DetailsFormScheme<
//     PolicyDetailsShape[K] | null
//   >;
// };

// type Entries<T> = {
//   [K in keyof T]: [K, T[K]];
// }[keyof T][];
// function getPolicyDetailsFields() {
//   const ApplicationShape = FrontendApplicationSchema.shape;
//   const PolicyDetails = ApplicationShape.policyDetails.shape;

//   const result = {}; // @ts-ignore
//   const entries = Object.entries(PolicyDetails) as Entries<
//     typeof PolicyDetails
//   >;

//   for (const [key, zodObject] of entries) {
//     let isOptional = false;
//     let options = null;
//     let type: 'string' | 'enum' = 'string';

//     if (zodObject._def.typeName === 'ZodOptional') {
//       isOptional = true;
//       if (zodObject._def.innerType._def.typeName === 'ZodString') {
//         type = 'string';
//       } else if (zodObject._def.innerType._def.typeName === 'ZodEnum') {
//         type = 'enum';
//         options = zodObject._def.innerType.options; // @ts-ignore
//       } else if (zodObject._def.innerType._def.typeName === 'ZodArray') {
//         type = 'enum';
//         options = zodObject._def.innerType.element.options; // @ts-ignore
//       }
//     } else if (zodObject._def.typeName === 'ZodString') {
//       type = 'string';
//     } else if (zodObject._def.typeName === 'ZodEnum') {
//       type = 'enum';
//       options = zodObject.options; // @ts-ignore
//     } else if (zodObject._def.typeName === 'ZodArray') {
//       type = 'enum';
//       options = zodObject.element.options; // @ts-ignore
//     }

//     const details = {
//       type,
//       optional: isOptional,
//       invalidate: (ctx: z.RefinementCtx) => {
//         ctx.addIssue({
//           path: [key],
//           message: 'Required',
//           expected: undefined,
//           received: undefined,
//           code: z.ZodIssueCode.invalid_literal,
//         });
//       },
//     };
//     if (options !== null) {
//       details.options = options; // @ts-ignore
//     }

//     result[key] = details;
//   }
//   return result;
// }

// export const getFormScheme = () => {
//   // "z.array(someSchema).optional" -> get optional, call .unwrap().element

//   const ApplicationShape = FrontendApplicationSchema.shape;

//   // const PolicyDetails = ApplicationShape.policyDetails.shape;
//   const practiceLocations =
//     ApplicationShape.practiceLocations.unwrap().element.shape;

//   // console.log(PolicyDetails);

//   // const insuranceLinesDesired = PolicyDetails.insuranceLinesDesired;

//   // const policyInfo = PolicyDetails.policyInfo.shape;
//   // const practiceLocations = PolicyDetails.practiceLocations.element.shape;

//   // console.log(PolicyDetails.practiceLocations.element.shape);
//   // const policyInfoObj = {};

//   // console.log(policyInfo);
//   // const policyDetailsFields = getFields(PolicyDetails);

//   // const test = getPolicyDetailsFields();
//   // console.log('test -', test);
//   // console.log('fields -', policyDetailsFields);
//   // console.log(policyDetailsFields);

//   const practiceLocationsFields = getFields(practiceLocations);

//   // console.log(policyInfoFields, 'fields');

//   // console.log('scheme - ', policyInfoObj);
//   return {
//     policyDetails: getPolicyDetailsFields(),
//     ...practiceLocationsFields,
//   };

//   // console.log(policyInfo.shape.rollOverExpiringPolicyNumber);
//   // console.log(insuranceLinesDesired.element.options);
// };

// export function getFormDefaults(obj: Result): Result {
//   const exclude = [
//     'practiceLocations',
//     'insuranceLinesDesired',
//     'professionalDesignation',
//   ]; // excluding these as they are not rendered as in schema, schema - options, design requirement - checkboxes;
//   return Object.entries(obj).reduce((acc, [key, value]) => {
//     if (exclude.includes(key)) {
//       return acc;
//     }
//     if (value.type === 'enum' || value.type === 'string') {
//       acc[key as keyof Result] = '';
//     }
//     return acc;
//   }, {} as Result);
// }

// export const getZodDefaults = (obj: Result) => {
//   const exclude = [
//     'practiceLocations',
//     'insuranceLinesDesired',
//     'professionalDesignation',
//   ]; // excluding these as they are not rendered as in schema, schema - options, design requirement - checkboxes;

//   return Object.entries(obj).reduce(
//     (acc, [key, value]) => {
//       if (exclude.includes(key)) {
//         return acc;
//       }

//       // if (key === 'firstNamedInsuredType') {
//       //   console.log(value);
//       // }

//       // if (value.type === 'enum') {
//       //   acc[key] = z.nativenum(value.options).optional();
//       // }

//       acc[key] = z.string().optional();

//       return acc;
//     },
//     {} as { [key: string]: z.ZodOptional<z.ZodString> }
//   );
// };

type Address = {
  line1: string[] | string;
  line2: string;
  city: string;
  county: string;
  zipCode: string;
  state: UsStateEnum;
  formatted: string;
};

export const getFormattedGooglePlaceResults = (
  e: google.maps.places.PlaceResult
) => {
  const res: Partial<Address & { formatted: string; line1: string }> = {
    formatted: e.formatted_address,
  };

  e.address_components?.forEach(({ long_name, short_name, types }) => {
    if (types.includes('street_number') && !res?.line1) {
      res.line1 = [long_name].join(' ');
    }

    if (types.includes('route')) {
      res.line1 = `${res.line1 || ''} ${long_name}`;
    }
    if (types.includes('subpremise')) {
      res.line2 = long_name;
    }

    if (types.includes('postal_code')) {
      res.zipCode = long_name;
    }

    if (types.includes('administrative_area_level_1')) {
      res.state = short_name as UsStateEnum;
    } else if (
      types.includes('country') &&
      (short_name == 'PR' || short_name == 'VI')
    ) {
      // Puerto Rico & Virgin Islands are considered as separate countries & not
      // administrative levels. All territories will need to use this field
      res.state = short_name as UsStateEnum;
    }

    if (types.includes('administrative_area_level_2')) {
      res.county = long_name;
    }

    if (types.includes('locality')) {
      res.city = long_name;
    }
  });

  return res;
};

export const getDisplayAddressFromAddress = (
  address: UsAddress | undefined | null
): string => {
  let displayAddress = '';
  if (address) {
    const { line1, line2, city, state } = address;
    displayAddress = [line1, line2, city, state].filter((v) => v).join(', ');
    displayAddress ? displayAddress.concat('', 'USA') : '';
  }

  return displayAddress;
};

// format as currency
export const formatAsCurrency = (num: string | number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(num));
};

export const formatAsPercentage = (num: string | number) => {
  return Number(Number(num) / 100).toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });
};

export const formatAsNumber = (num: string | number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format(Number(num));
};

/**
 * Example how to get all labels
 * const labels = FirstNamedInsuredTypeSchema.options.map((v) => FirstNamedInsuredTypeToLabel[v as FirstNamedInsuredType]);
 * */

// Think about how to use this function to get label for watch conditions in forms
export const getLabel = (key: string) => {
  const labels = {
    'Large Group': 'Large Group (10 Or More Dentists)',
    'Dental Association': 'Dental Association, Society Or Testing Board',
    'Volunteer Only': 'Solo Dentist, Volunteer Only',
    'Faculty Only': 'Solo Dentist, Faculty Only (Non Intramural)',
    Moonlighting: 'Solo Dentist, Moonlighting',
    'New Grad': '2 Year New Grad Policy',
    ASIC: 'ASPEN SPECIALTY INSURANCE COMPANY (ASIC)',
    AAIC: 'ASPEN AMERICAN INSURANCE COMPAY (AAIC)',
    Corporation: 'Corporation',
    Individual: 'Individual',
    LLC: 'LLC',
    LLP: 'LLP',
    Other: 'Other',
    Partnership: 'Partnership',
    'PC/PA': 'PC/PA',
  };

  return labels[key] || key;
};

// export const getKey = (label: string) => {
//   const keys = {
//     'Large Group (10 Or More Dentists)': 'Large Group',
//     'Dental Association, Society Or Testing Board': 'Dental Association',
//     'Solo Dentist, Volunteer Only': 'Volunteer Only',
//     'Solo Dentist, Faculty Only (Non Intramural)': 'Faculty Only',
//     'Solo Dentist, Moonlighting': 'Moonlighting',
//     '2 Year New Grad Policy': 'New Grad',
//     'ASPEN SPECIALTY INSURANCE COMPANY (ASIC)': 'ASIC',
//     AAIC: 'ASPEN AMERICAN INSURANCE COMPAY (AAIC)',
//     Corporation: 'Corporation',
//     Individual: 'Individual',
//     LLC: 'LLC',
//     LLP: 'LLP',
//     Other: 'Other',
//     Partnership: 'Partnership',
//     'PC/PA': 'PC/PA',
//   };
//   return keys[label];
// };

export const getMappedOptions = (options: string[]) => {
  return options.map((key) => {
    const label = getLabel(key);
    return {
      value: key,
      label,
      isFound: key !== label,
    };
  });
};
